<template>
  <div>
    <v-app-bar app fixed color="white">
      <!-- BURGER -->
      <v-app-bar-nav-icon
        color="primary"
        v-if="isMobile"
        @click="drawer = true"
      ></v-app-bar-nav-icon>

      <!-- DESKTOP -->
      <!-- Logo -->
      <router-link to="/">
        <v-avatar>
          <v-img
            v-if="!isMobile"
            :src="require('@/assets/logo-urps-small.png')"
          ></v-img>
        </v-avatar>
      </router-link>

      <!-- AppName -->
      <v-toolbar-title v-if="!isMobile" class="primary--text ml-4">
        {{ appName }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Navigation -->
      <div>
        <v-tabs
          :optional="true"
          v-if="!isMobile"
          hide-slider
          active-class="custom-active"
        >
          <v-tab
            v-for="(element, index) in menuItems"
            :key="index"
            :to="{ path: element.route }"
            :ripple="false"
            text
          >
            {{ element.label }}
          </v-tab>
        </v-tabs>
      </div>
      <!-- Boutons -->
      <div v-if="!isMobile">
        <btn
          v-for="(element, index) in buttonsItems"
          :key="index"
          :filled="element.filled"
          :to="{ path: element.route }"
          active-class="test"
          class="mr-4"
        >
          {{ element.label }}
        </btn>
      </div>
      <!-- User -->
      <div>
        <user-menu></user-menu>
      </div>
      <span class="mr-0 mr-sm-12"></span>
    </v-app-bar>

    <!-- MOBILE -->
    <v-navigation-drawer v-if="isMobile" v-model="drawer" absolute temporary>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title" color="primary">
            {{ appName }}
          </v-list-item-title>
          <v-list-item-subtitle>
            Menu
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <!-- menu -->
      <v-list-item
        v-for="(element, i) in menuItems"
        :key="i"
        class=""
        link
        :to="{ path: element.route }"
      >
        <v-list-item-icon>
          <v-icon>{{ element.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ element.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- buttons-->
      <v-list-item
        v-for="(element, i) in buttonsItems"
        :key="i + 'bis'"
        class=""
        link
        :to="{ path: element.route }"
      >
        <v-list-item-icon>
          <v-icon>{{ element.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ element.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect'

export default {
  name: 'TopNavBar',

  data: () => ({
    appName: process.env.VUE_APP_TITLE || 'OFFICINES',
    isMobile,
    drawer: false
  }),

  computed: {
    menuItems() {
      let menuItemsArray = [
        {
          route: '/',
          label: 'Accueil',
          icon: 'mdi-home'
        }
      ]

      /* In buttons
      if (this.isAdminConnected) {
        menuItemsArray.push({
          route: '/admin',
          label: 'Admin Board',
          icon: 'dashboard'
        })
      }
      */

      if (this.isUserConnected) {
        menuItemsArray.push(
          {
            route: '/information',
            label: 'Informations',
            icon: 'mdi-information-outline'
          }
          /* In buttons
          {
            route: '/historique',
            label: 'Historique des cas',
            icon: 'receipt'
          }
          */
        )
      }

      return menuItemsArray
    },

    buttonsItems() {
      if (!this.currentUser) {
        return [
          {
            label: 'Inscription',
            route: '/register',
            filled: true,
            icon: 'mdi-account-multiple-plus-outline'
          },
          {
            label: 'Connexion',
            route: '/login',
            filled: false,
            icon: 'mdi-login'
          }
        ]
      }
      if (this.isUserConnected) {
        return [
          {
            label: 'Saisie de formulaire',
            route: '/formulaire',
            filled: true,
            icon: 'mdi-file-document-outline'
          },
          {
            label: 'Historique',
            route: '/historique',
            filled: false,
            icon: 'mdi-table'
          }
        ]
      }
      if (this.isAdminConnected) {
        return [
          {
            label: 'Administration',
            route: '/admin/dashboard',
            filled: true,
            icon: 'mdi-dashboard'
          }
        ]
      }
    },
    currentUser() {
      return this.$store.state.auth.user
    },

    isUserConnected() {
      if (this.currentUser && this.currentUser.role) {
        return this.currentUser.role.includes('Utilisateur')
      }
      return false
    },

    isAdminConnected() {
      if (this.currentUser && this.currentUser.role) {
        return this.currentUser.role.includes('Administrateur')
      }
      return false
    }
  },

  methods: {
    /*
    logOut() {

      this.$store
        .dispatch('auth/logout')
        .then(response => {
          this.$toasted.show(response.data.message, {
            type: 'success',
            duration: '5000',
            position: 'top-center'
          })
          this.$router.push('/login')
        })
        .catch(err => {
          this.$toasted.show(err.error, {
            type: 'error',
            position: 'top-center',
            action: {
              text: 'Cancel',
              duration: '5000',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              }
            }
          })
        })
    }*/
  },

  components: {
    btn: () => import('./BasicBtn.vue'),
    'user-menu': () => import('./UserMenu.vue')
  }
}
</script>

<style scoped>
.v-tab {
  /*text-transform: none !important;*/
}
.custom-active {
  /*position: relative;*/
  overflow: visible;
}
.custom-active::after {
  content: ' ';
  position: absolute;
  z-index: 500;
  top: 54px;
  height: 3px;
  width: 100%;
  visibility: visible;
  background-color: var(--v-primary-base);
}
</style>
